import React, { useState, useEffect, lazy, Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';

// Lazy load MainPage
const MainPage = lazy(() => import('./MainPage'));

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <BrowserRouter>
      {isLoading ? (
        <div className="showX">
          <div id="preloader">
            <div className="loading-text">Loading</div>
          </div>
        </div>
      ) : (
        <Suspense fallback={<div className="showX"><div id="preloader"><div className="loading-text">Loading</div></div></div>}>
          <MainPage />
        </Suspense>
      )}
    </BrowserRouter>
  );
}

export default App;
